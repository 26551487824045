<template>
  <div class="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-white to-MDM-100 dark:from-slate-800  dark:to-MDM-900">
    <div class="text-center text-slate-900 dark:text-white text-5xl font-extrabold font-montserrat tracking-wide mb-10">
      WELCOME
    </div>
    <div class="relative">
      <img
        class="mx-auto"
        src="@/assets/images/personajes/Avatar MDM.svg"
        alt="Logo"
        width="170"
        height="265"
      />
      <div class="text-slate-500 dark:text-slate-400 text-3xl font-bold pt-1">My Digital Mind</div>
    </div>
    <div class="flex flex-col items-center mt-10">
      <button
        class="transition ease-in-out delay-150 bg-gray-800 dark:bg-slate-950 hover:-translate-y-1 hover:scale-110 hover:bg-gray-900 duration-300 w-64 h-12 text-white text-base font-normal tracking-tight rounded-lg mb-4"
        @click="loginWithGoogle"
      >
        Log in with Google
      </button>
      <button
        class="transition ease-in-out delay-150 bg-gray-300 dark:bg-slate-800 hover:-translate-y-1 hover:scale-110 hover:bg-gray-400 duration-300 w-64 h-12 text-neutral-500 dark:text-white text-base font-normal tracking-tight rounded-lg"
        @click="getStarted"
      >
        Continue as Guest
      </button>
    </div>
    <div class="text-center text-white text-sm mt-10">
      <button 
        @click="openPrivacyPage"
        class="transition ease-in-out delay-150 bg-gray-300 dark:bg-slate-800 hover:-translate-y-1 hover:scale-110 hover:bg-gray-400 duration-300 w-64 h-12 text-neutral-500 dark:text-white text-base font-normal tracking-tight rounded-lg"
      >
        Política de Privacidad
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "WelcomePage",
  data() {
    return {
      robotImage: require("@/assets/images/personajes/digitalmind-01.png"),
      backgroundImage: require("@/assets/images/business-background.png"),
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundBlendMode: "overlay",
      };
    },
  },
  
  methods: {
    getStarted() {
      // Implementar la lógica de navegación aquí
      window.location.href = 'https://mydigitalmind.ai/contacto'; // Cambia a la navegación interna si es necesario
    },
    loginWithGoogle() {
      // Redirige a la URL de inicio de sesión con Google
      window.location.href = 'https://mydigitalmind.ai/api/login';
    },
    openPrivacyPage() {
      // Abre la política de privacidad en una nueva pestaña
      window.open('https://mydigitalmind.ai/privacy', '_blank');
    }
  }
};
</script>
