<template>
  <div class="min-h-screen flex flex-col justify-center items-center bg-gradient-to-b from-white to-MDM-100 dark:from-slate-800 dark:to-MDM-900">
    <div class="bg-gray-100 dark:bg-gray-900 p-6 rounded-lg shadow-lg max-w-xl w-full">
      <h1 class="text-2xl font-bold mb-4 text-gray-900 dark:text-gray-100">My Digital Mind Directory</h1>
      <ul class="list-none">
        <li class="mb-8">
          <a href="https://mydigitalmind.ai/" class="text-blue-500 dark:text-blue-300 text-xl font-semibold hover:underline flex items-center">
            <ion-icon name="home-outline" class="mr-2"></ion-icon>
            Asistente General
          </a>
          <p class="text-gray-600 dark:text-gray-400 text-sm ml-8">El corazón de My Digital Mind, donde puedes interactuar con un asistente virtual capaz de responder preguntas, guiarte y mejorar tu experiencia en diferentes áreas.</p>
        </li>

        <li class="mb-8">
          <a href="https://mydigitalmind.ai/developer/" class="text-blue-500 dark:text-blue-300 text-xl font-semibold hover:underline flex items-center">
            <ion-icon name="briefcase-outline" class="mr-2"></ion-icon>
            Solei Tours (Agencia de Viajes)
          </a>
          <p class="text-gray-600 dark:text-gray-400 text-sm ml-8">Una solución personalizada para la gestión de viajes. Ofrece opciones de reserva, sugerencias de destinos y experiencias a través de un interfaz interactiva y fácil de usar.</p>
          <div class="ml-8 mt-2">
            <a href="https://wa.me/12512415196?text=Hola!%20Me%20podrías%20dar%20más%20información." class="text-green-500 dark:text-green-400 hover:underline flex items-center">
              <ion-icon name="logo-whatsapp" class="mr-2"></ion-icon>
              Contactar Asistente Solei Tours en WhatsApp
            </a>
          </div>
        </li>

        <li class="mb-8">
          <a href="https://mydigitalmind.ai/restaurante/" class="text-blue-500 dark:text-blue-300 text-xl font-semibold hover:underline flex items-center">
            <ion-icon name="restaurant-outline" class="mr-2"></ion-icon>
            Restaurante
          </a>
          <p class="text-gray-600 dark:text-gray-400 text-sm ml-8">Una plataforma diseñada para ayudar a los restaurantes a gestionar reservas, menús y pedidos en línea, optimizando la interacción con los clientes y mejorando la eficiencia del servicio.</p>
        </li>

        <li class="mb-8">
          <a href="https://mydigitalmind.ai/dentista/" class="text-blue-500 dark:text-blue-300 text-xl font-semibold hover:underline flex items-center">
            <ion-icon name="medkit-outline" class="mr-2"></ion-icon>
            Dentista
          </a>
          <p class="text-gray-600 dark:text-gray-400 text-sm ml-8">Una solución integral para la gestión de citas y expedientes médicos en clínicas dentales, facilitando la administración de pacientes y optimizando los procesos de atención.</p>
          <div class="ml-8 mt-2">
            <a href="https://wa.me/15592151922?text=Hola!%20Me%20podrías%20dar%20más%20información." class="text-green-500 dark:text-green-400 hover:underline flex items-center">
              <ion-icon name="logo-whatsapp" class="mr-2"></ion-icon>
              Contactar Asistente de Dentista en WhatsApp
            </a>
          </div>
        </li>

        <li class="mb-8">
          <a href="https://mydigitalmind.ai/afore/" class="text-blue-500 dark:text-blue-300 text-xl font-semibold hover:underline flex items-center">
            <ion-icon name="wallet-outline" class="mr-2"></ion-icon>
            Afore
          </a>
          <p class="text-gray-600 dark:text-gray-400 text-sm ml-8">Una herramienta diseñada para optimizar la gestión de tu fondo de retiro, brindando opciones de seguimiento y asesoramiento personalizado.</p>
        </li>
      </ul>

      <!-- Footer con el enlace de política de privacidad -->
      <footer class="mt-8">
        <a href="https://mydigitalmind.ai/privacy" class="text-gray-500 dark:text-gray-400 text-sm hover:underline">
          Política de Privacidad
        </a>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "DirectoryLinks",
  components: {},
  data() {
    return {
      showTerms: false,
      robotImage: require("@/assets/images/personajes/digitalmind-01.png"),
      backgroundImage: require("@/assets/images/business-background.png"),
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${this.backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundBlendMode: "overlay",
      };
    },
  },
};
</script>
